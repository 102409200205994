import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getTranslationVariantText } from "../../utils/helper";

const DateWidget = ()=> {
	const [time, setTime] = useState(moment());
	const intervalRef = useRef();

	useEffect(() => {
		intervalRef.current = setInterval(update, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	});

	function update() {
		setTime(moment());
	}

	return (
		<div className="m-1">
			{getTranslationVariantText(time.format('dddd'), "caption", {
				color: "#ffffff",
				fontSize: "11.5px",
				fontWeight: "400",
				whiteSpace: "nowrap",
				display: "inline-block"
			})}
			<Typography 
				variant="caption" 
				style={{
					color: "#ffffff",
					fontSize: "11.5px",
					fontWeight: "400",
					whiteSpace: "nowrap",
					display: "inline-block"
				}}
			>
				, {time.format('HH:mm')}
			</Typography>
		</div>
	);
}

export default React.memo(DateWidget);